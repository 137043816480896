export const CountriesLocale = {
  'ВЬЕТНАМ': {
    ru: 'ВЬЕТНАМ',
    en: 'VIETNAM',
    chi: '越南',
  },
  'ИНДИЯ': {
    ru: 'ИНДИЯ',
    en: 'INDIA',
    chi: '印度'
  },
  'КАЗАХСТАН': {
    ru: 'КАЗАХСТАН',
    en: 'KAZAKHSTAN',
    chi: '哈萨克斯坦'
  },
  'КИТАЙ': {
    ru: 'КИТАЙ',
    en: 'CHINA',
    chi: '中国'
  },
  'КОРЕЯ, РЕСПУБЛИКА': {
    ru: 'КОРЕЯ, РЕСПУБЛИКА',
    en: 'KOREA, REPUBLIC',
    chi: '韩国'
  },
  'ЛАОССКАЯ НАРОДНО-ДЕМОКРАТИЧЕСКАЯ РЕСПУБЛИКА': {
    ru: 'ЛАОССКАЯ НАРОДНО-ДЕМОКРАТИЧЕСКАЯ РЕСПУБЛИКА',
    en: "LAOS PEOPLE'S DEMOCRATIC REPUBLIC",
    chi: '老挝人民民主共和国'
  },
  'РОССИЯ': {
    ru: 'РОССИЯ',
    en: 'RUSSIA',
    chi: '俄罗斯'
  },
  'СИНГАПУР': {
    ru: 'СИНГАПУР',
    en: 'SINGAPORE',
    chi: '新加坡'
  },
  'ТАИЛАНД': {
    ru: 'ТАИЛАНД',
    en: 'THAILAND',
    chi: '泰国'
  },
  'ТАЙВАНЬ (КИТАЙ)': {
    ru: 'ТАЙВАНЬ (КИТАЙ)',
    en: 'TAIWAN (CHINA)',
    chi: '台湾（中国）'
  },
  'Тайвань': {
    ru: 'Тайвань',
    en: 'Taiwan',
    chi: '台湾'
  },
  'ТУРЦИЯ': {
    ru: 'ТУРЦИЯ',
    en: 'TURKEY',
    chi: '土耳其'
  },
  'ЯПОНИЯ': {
    ru: 'ЯПОНИЯ',
    en: 'JAPAN',
    chi: '日本'
  },
  'МАЛАЙЗИЯ': {
    ru: 'МАЛАЙЗИЯ',
    en: 'MALAYSIA',
    chi: '马拉西亚'
  },
  'БЕЛАРУСЬ': {
    ru: 'БЕЛАРУСЬ',
    en: 'BELARUS',
    chi: '白俄罗斯'
  },
  'КАМБОДЖА': {
    ru: 'КАМБОДЖА',
    en: 'CAMBODIA',
    chi: '柬埔寨'
  },
  'БАНГЛАДЕШ': {
    ru: 'БАНГЛАДЕШ',
    en: 'BANGLADESH',
    chi: '孟加拉国'
  },
  'ФИЛИППИНЫ': {
    ru: 'ФИЛИППИНЫ',
    en: 'PHILIPPINES',
    chi: '菲律宾'
  },
  'ИНДОНЕЗИЯ': {
    ru: 'ИНДОНЕЗИЯ',
    en: 'INDONESIA',
    chi: '印度尼西亚'
  },
  'СОЕДИНЕННЫЕ ШТАТЫ': {
    ru: 'СОЕДИНЕННЫЕ ШТАТЫ',
    en: 'UNITED STATES',
    chi: '美国'
  },
  'ГЕРМАНИЯ': {
    ru: 'ГЕРМАНИЯ',
    en: 'GERMANY',
    chi: '德国'
  },
  'ПАКИСТАН': {
    ru: 'ПАКИСТАН',
    en: 'PAKISTAN',
    chi: '巴基斯坦'
  },
  'ОБЪЕДИНЕННЫЕ АРАБСКИЕ ЭМИРАТЫ': {
    ru: 'ОБЪЕДИНЕННЫЕ АРАБСКИЕ ЭМИРАТЫ',
    en: 'UNITED ARAB EMIRATES',
    chi: '阿拉伯聯合酋長國'
  },
  'УЗБЕКИСТАН': {
    ru: 'УЗБЕКИСТАН',
    en: 'UZBEKISTAN',
    chi: '烏茲別克斯坦'
  },
  'БРАЗИЛИЯ': {
    ru: 'БРАЗИЛИЯ',
    en: 'BRAZIL',
    chi: '巴西'
  },
  'ЕГИПЕТ': {
    ru: 'ЕГИПЕТ',
    en: 'EGYPT',
    chi: '埃及'
  },
  'ИЗРАИЛЬ': {
    ru: 'ИЗРАИЛЬ',
    en: 'ISRAEL',
    chi: '以色列',
  },
  'НИДЕРЛАНДЫ': {
    ru: 'НИДЕРЛАНДЫ',
    en: 'NETHERLANDS',
    chi: '荷蘭'
  },
  'АВСТРАЛИЯ': {
    ru: 'АВСТРАЛИЯ',
    en: 'AUSTRALIA',
    chi: '澳大利亞'
  },
  'САУДОВСКАЯ АРАВИЯ': {
    ru: 'САУДОВСКАЯ АРАВИЯ',
    en: 'SAUDI ARABIA',
    chi: '沙特阿拉伯'
  },
  'НОВАЯ ЗЕЛАНДИЯ': {
    ru: 'НОВАЯ ЗЕЛАНДИЯ',
    en: 'NEW ZEALAND',
    chi: '新西蘭'
  },
  'КАНАДА': {
    ru: 'КАНАДА',
    en: 'CANADA',
    chi: '加拿大'
  },
  'ПЕРУ': {
    ru: 'ПЕРУ',
    en: 'PERU',
    chi: '秘魯'
  },
  'ЧИЛИ': {
    ru: 'ЧИЛИ',
    en: 'CHILE',
    chi: '智利'
  },
  'МЕКСИКА': {
    ru: 'МЕКСИКА',
    en: 'MEXICO',
    chi: '墨西哥'
  },
  'ЮЖНАЯ АФРИКА': {
    ru: 'ЮЖНАЯ АФРИКА',
    en: 'SOUTH AFRICA',
    chi: '南非'
  },
  'КАТАР': {
    ru: 'КАТАР',
    en: 'QATAR',
    chi: '卡塔爾'
  },
  'АЛЖИР': {
    ru: 'АЛЖИР',
    en: 'ALGERIA',
    chi: '阿爾及利亞'
  },
  'ТУНИС': {
    ru: 'ТУНИС',
    en: 'TUNISIA',
    chi: '突尼斯'
  },
  'МАРОККО': {
    ru: 'МАРОККО',
    en: 'MOROCCO',
    chi: '摩洛哥'
  },
  'ЛИВИЯ': {
    ru: 'ЛИВИЯ',
    en: 'LIBYA',
    chi: '利比亞',
  },
  'ИСПАНИЯ': {
    ru: 'ИСПАНИЯ',
    en: 'SPAIN',
    chi: '西班牙',
  },
  'РУМЫНИЯ': {
    ru: 'РУМЫНИЯ',
    en: 'ROMANIA',
    chi: '羅馬尼亞',
  },
  'ИТАЛИЯ': {
    ru: 'ИТАЛИЯ',
    en: 'ITALY',
    chi: '意大利'
  },
  'БЕЛЬГИЯ': {
    ru: 'БЕЛЬГИЯ',
    en: 'BELGIUM',
    chi: '比利時'
  },
  'ЛИВАН': {
    ru: 'ЛИВАН',
    en: 'LEBANON',
    chi: '黎巴嫩'
  },
  'ШРИ-ЛАНКА': {
    ru: 'ШРИ-ЛАНКА',
    en: 'SRI LANKA',
    chi: '斯里蘭卡'
  },
  'МОНГОЛИЯ': {
    ru: 'МОНГОЛИЯ',
    en: 'MONGOLIA',
    chi: '蒙古'
  },
  "КИРГИЗИЯ": {
    ru: 'КИРГИЗИЯ',
    en: 'KYRGYZSTAN',
    chi: '吉爾吉斯斯坦'
  },
  "ТАДЖИКИСТАН": {
    ru: 'ТАДЖИКИСТАН',
    en: 'TAJIKISTAN',
    chi: '塔吉克斯坦'
  },
  "ТУРКМЕНИЯ": {
    ru: 'ТУРКМЕНИЯ',
    en: 'TURKMENIA',
    chi: '土庫曼尼亞'
  },
  "ИРАН, ИСЛАМСКАЯ РЕСПУБЛИКА": {
    ru: 'ИРАН, ИСЛАМСКАЯ РЕСПУБЛИКА',
    en: 'IRAN, ISLAMIC REPUBLIC',
    chi: '伊朗伊斯蘭共和國'
  },
  "ОМАН": {
    ru: 'ОМАН',
    en: 'OMAN',
    chi: '阿曼'
  },
  "МЬЯНМА": {
    ru: 'МЬЯНМА',
    en: 'MYANMAR',
    chi: '緬甸'
  },
  "НОРВЕГИЯ": {
    ru: 'НОРВЕГИЯ',
    en: 'NORWAY',
    chi: '挪威'
  },
  "ПОЛЬША": {
    ru: 'ПОЛЬША',
    en: 'POLAND',
    chi: '波蘭'
  },
  "СЛОВЕНИЯ": {
    ru: 'СЛОВЕНИЯ',
    en: 'SLOVENIA',
    chi: '斯洛文尼亞'
  },
  "СОЕДИНЕННОЕ КОРОЛЕВСТВО": {
    ru: 'СОЕДИНЕННОЕ КОРОЛЕВСТВО',
    en: 'UNITED KINGDOM',
    chi: '英國'
  },
  "ФИНЛЯНДИЯ": {
    ru: 'ФИНЛЯНДИЯ',
    en: 'FINLAND',
    chi: '芬蘭'
  },
  "ФРАНЦИЯ": {
    ru: 'ФРАНЦИЯ',
    en: 'FRANCE',
    chi: '法國'
  },
  "ШВЕЦИЯ": {
    ru: 'ШВЕЦИЯ',
    en: 'SWEDEN',
    chi: '瑞典'
  },
  "ДЖИБУТИ": {
    ru: 'ДЖИБУТИ',
    en: 'DJIBOUTI',
    chi: '吉布提'
  },
  "БАХРЕЙН": {
    ru: 'БАХРЕЙН',
    en: 'BAHRAIN',
    chi: '巴林'
  },
  "АЗЕРБАЙДЖАН": {
    ru: 'АЗЕРБАЙДЖАН',
    en: 'AZERBAIJAN',
    chi: '阿塞拜疆'
  },
  "БОЛГАРИЯ": {
    ru: 'БОЛГАРИЯ',
    en: 'BULGARIA',
    chi: '保加利亞'
  },
  "ИОРДАНИЯ": {
    ru: 'ИОРДАНИЯ',
    en: 'JORDAN',
    chi: '約旦'
  },
  "ИРАК": {
    ru: 'ИРАК',
    en: 'IRAQ',
    chi: '伊拉克'
  },
  "КЕНИЯ": {
    ru: 'КЕНИЯ',
    en: 'KENYA',
    chi: '肯尼亞'
  },
  "ТАНЗАНИЯ, ОБЪЕДИНЕННАЯ РЕСПУБЛИКА": {
    ru: 'ТАНЗАНИЯ, ОБЪЕДИНЕННАЯ РЕСПУБЛИКА',
    en: 'TANZANIA',
    chi: '坦桑尼亞'
  },
  "КУВЕЙТ": {
    ru: 'КУВЕЙТ',
    en: 'KUWAIT',
    chi: '科威特'
  }
}
