export const PanelLocale = {
  сквозные_сервисы_заголовок: {
    ru: (
      <>
        СКВОЗНЫЕ
        <br />
        СЕРВИСЫ
      </>
    ),
    en: (
      <>
        THROUGH
        <br />
        SERVICES
      </>
    ),
    chi: <>端到端服务</>,
  },
  сквозной_сервис: {
    ru: 'Сквозной сервис',
    en: 'Through service',
    chi: '端到端服務',
  },
  город_отправления: {
    ru: 'Город отправления',
    en: 'Departure city',
    chi: '始发地城市',
  },
  город_назначения: {
    ru: 'Город назначения',
    en: 'Destination city',
    chi: '目的地城市',
  },
  агент: {
    ru: 'Агент',
    en: 'Agent',
    chi: '代理人',
  },
  дата_ставки: {
    ru: 'Дата ставки',
    en: 'Rate date',
    chi: '支付日期',
  },
  город_отправления_город_назначения: {
    ru: 'Город отправления / Город назначения',
    en: 'Departure city / Destination city',
    chi: '始发地城市 / 目的地城市',
  },
  размер: {
    ru: 'Размер',
    en: 'Size',
    chi: '尺寸',
  },
  принадлежность: {
    ru: 'Принадлежность',
    en: 'Owned by',
    chi: '配件',
  },
  цена: {
    ru: 'Цена',
    en: 'Price',
    chi: '价格',
  },
  цена_за: {
    ru: 'Цена за',
    en: 'Price for',
    chi: '结算',
  },
  комментарии: {
    ru: 'Комментарии',
    en: 'Comments',
    chi: '评论',
  },
  размер_контейнера: {
    ru: 'Размер контейнера',
    en: 'Container size',
    chi: '集装箱尺寸',
  },
  все: {
    ru: 'Все',
    en: 'All',
    chi: '全部',
  },
  20: {
    ru: '20',
    en: '20',
    chi: '20',
  },
  '20_тяж.': {
    ru: '20 тяж.',
    en: '20 (heavy)',
    chi: '20 重型',
  },
  40: {
    ru: '40',
    en: '40',
    chi: '40',
  },
  принадлежность_контейнера: {
    ru: 'Принадлежность контейнера',
    en: 'Container owned by',
    chi: '集装箱配件',
  },
  COC: {
    ru: 'COC',
    en: 'COC',
    chi: 'COC',
  },
  SOC: {
    ru: 'SOC',
    en: 'SOC',
    chi: 'SOC',
  },
  интервал_времени: {
    ru: 'Интервал времени',
    en: 'Time frame',
    chi: '时间间隔',
  },
  текущие_даты: {
    ru: 'Текущие даты',
    en: 'Current dates',
    chi: '当前日期',
  },
  будущие_даты: {
    ru: 'Будущие даты',
    en: 'Future dates',
    chi: '预计日期',
  },
  импорт0: {
    ru: 'Импорт НДС 0%',
    en: 'Import VAT 0%',
    chi: '进口增值税 0%',
  },
  экспорт0: {
    ru: 'Экспорт НДС 0%',
    en: 'Export VAT 0%',
    chi: '出口增值税 0%',
  },
  каботаж20: {
    ru: 'Каботаж НДС 20%',
    en: 'Cabotage VAT 20%',
    chi: '沿海运输增值税 20%',
  },
  прямое_жд_кнр_рф0: {
    ru: 'Прямое ЖД КНР - РФ НДС 0%',
    en: 'Through railway service China - Russia VAT 0%',
    chi: '中国-俄罗斯铁路直达增值税 0%',
  },
  применить: {
    ru: 'Применить',
    en: 'Apply',
    chi: '应用',
  },
  очистить_всё: {
    ru: 'Очистить всё',
    en: 'Clear all',
    chi: '清除所有',
  },
  фрахт_заголовок: {
    ru: <>ФРАХТ</>,
    en: <>FREIGHT</>,
    chi: <>运费</>,
  },
  drop_off_заголовок: {
    ru: <>DROP OFF</>,
    en: <>DROP OFF</>,
    chi: <>DROP OFF</>,
  },
  пункт_отправления: {
    ru: 'Пункт отправления',
    en: 'Departure point',
    chi: '始发站点',
  },
  пункт_назначения: {
    ru: 'Пункт назначения',
    en: 'Destination point',
    chi: '目的地港口',
  },
  город_сдачи_порожнего_контейнера: {
    ru: 'Город сдачи порожнего контейнера DROP OFF',
    en: 'Drop-off city',
    chi: '空集装箱交付城市',
  },
  линия: {
    ru: 'Линия',
    en: 'Line',
    chi: '线路',
  },
  порт_назначения: {
    ru: 'Порт назначения',
    en: 'Destination port',
    chi: '抵達港口',
  },
  порт_отправления_порт_назначения: {
    ru: 'Порт отправления / Порт назначения',
    en: 'Departure port / Destination port',
    chi: '始发港口 / 目的地港口',
  },
  город_сдачи: {
    ru: 'Город сдачи',
    en: 'Drop-off city',
    chi: '交付城市',
  },
  ставка_фрахта: {
    ru: 'Ставка фрахта',
    en: 'Freight rate',
    chi: '运费价目',
  },
  ставка_drop_off: {
    ru: 'Ставка DROP OFF',
    en: 'Drop-off',
    chi: 'Drop-off',
  },
  жд_заголовок: {
    ru: <>ЖД</>,
    en: <>RAILWAY</>,
    chi: <>铁路</>,
  },
  станция_отправления: {
    ru: 'Станция отправления',
    en: 'Departure station',
    chi: '始发站',
  },
  станция_назначения: {
    ru: 'Станция назначения',
    en: 'Destination station',
    chi: '终点站',
  },
  город_станция_терминал_отправления_назначения: {
    ru: 'Город, станция, терминал отправления / назначения',
    en: 'City, station, departure / destination terminal',
    chi: '始发 / 目的地城市，站，终端站点',
  },
  ндс: {
    ru: 'НДС',
    en: 'VAT',
    chi: '增值税',
  },
  погранпереход: {
    ru: 'Погранпереход',
    en: 'Border point',
    chi: '过境',
  },
  терминал_отправления: {
    ru: 'Терминал отправления',
    en: 'Departure terminal',
    chi: '始发终端站点',
  },
  терминал_назначения: {
    ru: 'Терминал назначения',
    en: 'Destination terminal',
    chi: '目的地终端站点',
  },
  выдача_аренда_ктк_заголовок: {
    ru: (
      <>
        ВЫДАЧА /<br />
        АРЕНДА КТК
      </>
    ),
    en: (
      <>
        CONTAINER <br />
        RELEASE / RENTAL
      </>
    ),
    chi: (
      <>
        分配 /<br />
        租用集装箱
      </>
    ),
  },
  город_выдачи: {
    ru: 'Город выдачи',
    en: 'Pick-up city',
    chi: '分配城市',
  },
  пункт_выдачи_город_сдачи: {
    ru: 'Пункт выдачи / город сдачи',
    en: 'Pick-up point / drop-off city',
    chi: '分配城市 / 交付城市',
  },
  дней_бесплатного_пользования: {
    ru: 'Дней бесплатного пользования',
    en: 'Number of free days',
    chi: '免费试用期',
  },
  сверхнормативное_пользование: {
    ru: 'Сверхнормативное пользование',
    en: 'Excess use',
    chi: '逾期使用',
  },
  автовывоз_заголовок: {
    ru: <>АВТОВЫВОЗ</>,
    en: <>TRUCK PICK-UP</>,
    chi: <>自取</>,
  },
  пункт_отправления_назначения: {
    ru: 'Пункт отправления / назначения',
    en: 'Departure / destination point',
    chi: '始发站点 / 目的地站点',
  },
  расстояние: {
    ru: 'Расстояние',
    en: 'Distance',
    chi: '距离',
  },
  скоро_добавим: {
    ru: 'Скоро добавим',
    en: 'Coming soon',
    chi: '即将送到',
  },
  сборные_грузы_заголовок: {
    ru: <>СБОРНЫЕ ГРУЗЫ</>,
    en: <>LCL</>,
    chi: <>合并货物</>,
  },
  карта_терминалов_заголовок: {
    ru: (
      <>
        КАРТА
        <br />
        ТЕРМИНАЛОВ
      </>
    ),
    en: (
      <>
        TERMINAL
        <br />
        MAP
      </>
    ),
    chi: <>终端站点地图</>,
  },
  условия_ставки: {
    ru: 'Условия ставки',
    en: 'Terms',
    chi: '价目条件',
  },
  количество_отправлений_в_неделю: {
    ru: 'Кол-во отправлений в неделю',
    en: 'Quantaty of shipments in week',
    chi: '每周发货数量',
  },
  срок_ожидания: {
    ru: 'Срок ожидания',
    en: 'Term of staying',
    chi: '等待时间',
  },
  валидность: {
    ru: 'Валидность',
    en: 'Period of validity',
    chi: '有效期',
  },
  норма_погрузки_выгрузки: {
    ru: 'Норма погрузки / выгрузки',
    en: 'Rate of loading/unloading',
    chi: '装卸标准',
  },
  простой: {
    ru: 'Простой',
    en: 'Downtime',
    chi: '普通的',
  },
  полный_прайс: {
    ru: 'Полный прайс',
    en: 'Full price',
    chi: '完整的价目表',
  },
  стоимость_склада: {
    ru: 'Стоимость склада',
    en: 'Warehouse price',
    chi: '仓储价格',
  },
  адрес_склада: {
    ru: 'Адрес склада',
    en: 'Warehouse address',
    chi: '仓库地址',
  },
  телефон: {
    ru: 'Телефон',
    en: 'Telephone',
    chi: '电话号码',
  },
  показать_телефон: {
    ru: 'Показать телефон',
    en: 'Show phone',
    chi: '顯示手機',
  },
  показать_почту: {
    ru: 'E-mail',
    en: 'Show email',
    chi: '顯示電子郵件',
  },
  email: {
    ru: 'E-mail',
    en: 'E-mail',
    chi: '电子邮箱',
  },
  не_указано: {
    ru: 'Не указано',
    en: 'Not specified',
    chi: '未指定',
  },
  не_указан: {
    ru: 'Не указан',
    en: 'Not specified',
    chi: '未指定',
  },
  терминал_не_указан: {
    ru: 'Терминал не указан',
    en: 'Terminal not specified',
    chi: '未指定終端',
  },
  загрузка: {
    ru: 'Загрузка',
    en: 'Loading',
    chi: '正在加載',
  },
  coc_контейнер_перевозчика: {
    ru: 'Контейнер перевозчика',
    en: 'Container of carrier',
    chi: '集装箱承运方',
  },
  soc_контейнер_заказчика: {
    ru: 'Контейнер заказчика',
    en: 'Container of customer',
    chi: '私人集装箱',
  },
  coc_контейнер_перевозчика_soc_контейнер_заказчика: {
    ru: 'COC - контейнер перевозчика, SOC - контейнер заказчика',
    en: 'COC - container of carrier, SOC - container of customer',
    chi: 'COC - 集装箱承运方, SOC - 私人集装箱',
  },
  объем_м3_вес_кг_т: {
    ru: 'Объем - м3, Вес - кг/т',
    en: 'Volume-m cubic, weight-t/kg',
    chi: '体积 - 立方米，重量 - 千克/吨',
  },
  смотреть_детали: {
    ru: 'Смотреть детали',
    en: 'Detailes',
    chi: '详细查看',
  },
  объем: {
    ru: 'Объем',
    en: 'Volume',
    chi: '体积',
  },
  вес: {
    ru: 'Вес',
    en: 'Weight',
    chi: '重量',
  },
  'объем/вес': {
    ru: 'Объем/Вес',
    en: 'Volume/Weight',
    chi: '体积/重量',
  },
  список_пуст: {
    ru: 'Список пуст',
    en: 'List is empty',
    chi: '列表為空',
  },
  совпадений_нет: {
    ru: 'Совпадений нет',
    en: 'No matches',
    chi: '沒有匹配',
  },
  подтвердите_отправку_заявки: {
    ru: 'Подтвердите отправку заявки',
    en: 'Confirm the submission of the application',
    chi: '确认提交申请',
  },
  перевозчик_свяжется_с_вами_напрямую: {
    ru: 'Перевозчик свяжется с вами напрямую для оформления перевозки',
    en: 'The carrier will contact you directly to arrange transportation',
    chi: '承运商将直接与您联系并安排运输',
  },
  перевозчик: {
    ru: 'Перевозчик',
    en: 'Carrier',
    chi: '承运商',
  },
  количество_контейнеров: {
    ru: 'Количество контейнеров',
    en: 'Number of containers',
    chi: '集装箱数量',
  },
  отправить_заявку: {
    ru: 'Отправить заявку',
    en: 'Submit an application',
    chi: '发送申请',
  },
  отмена: {
    ru: 'Отмена',
    en: 'Cancel',
    chi: '取消',
  },
  ваша_заявка_успешно_отправлена: {
    ru: 'Ваша заявка успешно отправлена!',
    en: 'Your application has been successfully sent!',
    chi: '您的申请已成功发送！',
  },
  ждите_ответа_от_перевозчика: {
    ru: 'Ждите ответа от перевозчика на ваш email',
    en: 'Wait for a response from the carrier to your email',
    chi: '承运商会回复您电子邮件',
  },
}
